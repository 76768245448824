import { ValueOfObject } from 'src/types/generics';
import { HasStatus, Submittable } from 'src/api/Interfaces';
import { MediaObject } from 'src/api/services/MediaClient';

export const defaultSortFilter = new URLSearchParams({
  sorts: JSON.stringify([
    { id: 'priority', desc: true },
    { id: 'submitted_at', desc: false },
  ]),
}).toString();

const requestPriority = {
  low: 1,
  medium: 2,
  high: 3,
  critical: 4,
} as const;

type RequestPriorityType = ValueOfObject<typeof requestPriority>;

const requestPriorityLabels = {
  [requestPriority.low]: 'Low',
  [requestPriority.medium]: 'Medium',
  [requestPriority.high]: 'High',
  [requestPriority.critical]: 'Critical',
};

export const requestListType = {
  list: 'list',
  kanban: 'kanban',
} as const;

export type RequestListType = ValueOfObject<typeof requestListType>;

const requestStatus = {
  queued: 'queued',
  currentlyEditing: 'currently_editing',
  delivered: 'delivered',
  complete: 'complete',
} as const;

type RequestStatus = ValueOfObject<typeof requestStatus>;

interface RequestsTableResponse extends HasStatus<RequestStatus>, Submittable {
  id: string;
  flow: 'default' | 'multi_outcome' | 'outcome' | string;
  priority: RequestPriorityType;
  title: string;
  type: string;
  editor_note?: string;
  reviewed_by_editor: string;
  reviewed_by_customer: string;
  approved_at?: string;
  transcript_attached?: string;
  requested_reedit_at?: string;
  requested_attention_at?: string;
  attention_text?: string;
  created_at: Date | string;
  updated_at: Date | string;
  resolutions?: OrderResolutionsObject;
  assigned_editor?: {
    id: string;
    first_name: string;
    last_name: string;
    full_name: string;
    avatar?: MediaObject;
  };
  company?: {
    id: string;
    name: string;
  };
  brand?: {
    id: string;
    name: string;
  };

  outcomes_count: number;
  complete_outcomes_count: number;
  delivered_outcomes_count: number;
  queued_outcomes_count: number;
  editing_outcomes_count: number;
}

interface OrderResolutionsObject {
  main_size?: string;
  resize?: string[];
}

type RequestsCount = {
  total: string[];
  new: string[];
};

type RequestsCountsResponse = {
  [key in RequestStatus]: RequestsCount;
};

interface RequestAttentionRequest {
  attention_text: string;
}

export type {
  OrderResolutionsObject,
  RequestStatus,
  RequestsTableResponse,
  RequestsCount,
  RequestsCountsResponse,
  RequestPriorityType,
  RequestAttentionRequest,
};
export { requestStatus, requestPriority, requestPriorityLabels };
